// noinspection JSLint
import jQuery from "jquery"; // jshint ignore:line
// noinspection JSLint
window.$ = window.jQuery = jQuery;
// noinspection JSLint
import * as M from "@materializecss/materialize"; // jshint ignore:line
import * as wNumb from "wnumb";
import * as noUiSlider from "nouislider";
window.noUiSlider = noUiSlider;

document.addEventListener("DOMContentLoaded", function () {
  var elems = document.querySelectorAll(".modal");
  var instances = M.Modal.init(elems);
  var elems = document.querySelectorAll(".collapsible");
  var instances = M.Collapsible.init(elems, { accordion: false });
  var elems = document.querySelectorAll(".dropdown-trigger");
  var instances = M.Dropdown.init(elems);
});

window.setUrlParameter = function (url, key, value) {
  var urlSplit = url.split("?");
  var baseUrl = urlSplit[0],
    urlQueryString = "?" + urlSplit[1],
    newParam = key + "=" + value,
    params = "?" + newParam;

  // If the "search" string exists, then build params from it
  if (urlSplit[1]) {
    var updateRegex = new RegExp("([?&])" + key + "[^&]*");
    var removeRegex = new RegExp("([?&])" + key + "=[^&;]*[&;]?");

    if (typeof value === "undefined" || value === null || value === "") {
      // Remove param if value is empty
      params = urlQueryString.replace(removeRegex, "$1");
      params = params.replace(/[&;]$/, "");
    } else if (urlQueryString.match(updateRegex) !== null) {
      // If param exists already, update it
      params = urlQueryString.replace(updateRegex, "$1" + newParam);
    } else {
      // Otherwise, add it to end of query string
      params = urlQueryString + "&" + newParam;
    }
  }

  // no parameter was set so we don't need the question mark
  params = params === "?" ? "" : params;

  return baseUrl + params;
};

$(document).ready(function () {
  $(".merchant-filter").on("click", function (e) {
    window.location.href = setUrlParameter(
      setUrlParameter(
        window.location.href,
        "store",
        $(this).find("input")[0].value
      ),
      "page",
      ""
    );
  });
  $(".brand-filter").on("click", function (e) {
    window.location.href = setUrlParameter(
      setUrlParameter(
        window.location.href,
        "brand",
        $(this).find("input")[0].value
      ),
      "page",
      ""
    );
  });
  $(".discount-filter").on("click", function (e) {
    window.location.href = setUrlParameter(
      setUrlParameter(
        window.location.href,
        "discount",
        $(this).find("input")[0].value
      ),
      "page",
      null
    );
  });

  $("#sortBy").on("change", function (e) {
    window.location.href = setUrlParameter(
      setUrlParameter(window.location.href, "order", $("#sortBy")[0].value),
      "page",
      ""
    );
  });
  $("#clearSearch").on("click", function (e) {
    let input = $("input#query");
    input.val("");
    input.focus();
  });
  $("#showFilter").on("click", function (e) {
    $("#filters").toggle();
  });

  var slider = $("#price-slider")[0];
  if (slider) {
    var sliderValue = $("#price-slider-value")[0];
    var minPriceUrl = parseInt(slider.getAttribute("data-min-price-url"));
    var maxPriceUrl = parseInt(slider.getAttribute("data-max-price-url"));
    noUiSlider.create(slider, {
      snap: false,
      start: [minPriceUrl || 0, maxPriceUrl || 2000],
      connect: true,
      step: 1,
      orientation: "horizontal", // 'horizontal' or 'vertical'
      range: {
        min: 0,
        max: 2000,
      },
      format: wNumb({
        decimals: 0,
        encoder: function (a) {
          return Math.round(a * 100) / 100;
        },
        to: function (e) {
          return e == 2000 ? "2000+" : Math.round(e);
        },
        from: function (e) {
          return Math.round(Number(e));
        },
      }),
    });
    slider.noUiSlider.on("change.one", function (values) {
      window.location.href = setUrlParameter(
        setUrlParameter(
          setUrlParameter(window.location.href, "min_price", values[0]),
          "max_price",
          values[1]
        ),
        "page",
        ""
      );
    });
    // When the slider value changes, update the input and span
    slider.noUiSlider.on("update", function (values, handle) {
      sliderValue.innerHTML = values[0] + " - " + values[1];
    });
  }
});
